import { Flex, Spacer } from "@hackthenorth/north";
import { XMarkIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import {
  Button,
  Table,
  Overlay,
  Select,
  ButtonGroup,
  DropdownMenu,
  TextInput,
  Checkbox,
  DropdownMenuItem,
  Text,
} from "north.js";
import React, { useState, useEffect, useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";

// Autosave, Shipping Swag, Table Select, Sponsor T&C, Merge Conflicts
import { useGetHackersQuery } from "src/api/sponsors/getHackers.generated";
import { errorToast, successToast } from "src/shared/components";
import { Icon } from "src/shared/components";
import { Spinner } from "src/shared/components";
import Header, { TOPNAVBAR_HEIGHT } from "src/shared/components/Header";
import { IconName } from "src/shared/components/Icon";
import { APPLICATIONS_SLUG } from "src/shared/constants/event";
import { useSponsorContext } from "src/shared/contexts";
import { answersToNorthV2Options } from "src/shared/utils/react-select";
import globalConstants from "src/theme/globalConstants";

import { usePerkState } from "../perks/components/Perk/usePerkState";
import { SponsorPerkType } from "../perks/types";

import { THackerProfile } from "./components/HackerProfile/types";
import { SCHOOL_OPTIONS, PROGRAM_OPTIONS } from "./constants";
import { parseHackerProfiles, parseFilters, THackerFilter } from "./utils";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// React PDF Config
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Constants
const DEFAULT_NUM_PROFILES = 10; // Keep in mind that file limit on HAPI is 10
const DEFAULT_SCHOOL_TO_ADD_ON_FILTER = "The University of Waterloo";
const DEFAULT_PROGRAM_TO_ADD_ON_FILTER = "Computer Science";
// Store hacker csv in Spongebub Stage Time Perk
const HACKER_CSV_DOWNLOAD_LINK =
  "https://hackerapi.storage.googleapis.com/files/hackthenorth2024/text/csv/5ee361d168a2ad02557a16d11947ab52/4dcd36d7_1727836142_2024-hacker-resumes.csv?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=GOOG1E7VQ63ECFS25JDMAPYUWPWPIWLAW533KMAOEV5W5PDUSPXD2752SNY4A%2F20241002%2Fnorthamerica-northeast1%2Fs3%2Faws4_request&X-Amz-Date=20241002T023236Z&X-Amz-Expires=604800&X-Amz-Signature=ea04272a6c41885ff4074a35fc90faf14e5234e8b165922b7a61ddb47240befd&X-Amz-SignedHeaders=host&x-id=GetObject";

const SponsorRecruitment = () => {
  // pdf viewer
  const [overlayOpen, setOverlayOpen] = useState<boolean>(false);
  const [numPDFPages, setNumPDFPages] = useState<null | number>(null);
  const [pagePDFNumber, setPagePDFNumber] = useState<number>(1);
  const [selectedPDFUri, setSelectedPDFUri] = useState<string>("");
  const [selectedHackerId, setSelectedHackerId] = useState<string>("");

  // pagination
  const [page, setPage] = useState(0);
  const [skipped, setSkipped] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_NUM_PROFILES);

  // filters
  const [filters, setFilters] = useState<THackerFilter[]>([]);
  const [query, setQuery] = useState<string>("");
  const [laggedQuery, setLaggedQuery] = useState<string>("");
  const [schools, setSchools] = useState<string[]>([]);
  const [programs, setPrograms] = useState<string[]>([]);
  const [starred, setStarred] = useState<boolean>(false);
  const [colsNotVisible, setColsNotVisible] = useState<string[]>([]);
  const [gradYearRange, setGradYearRange] = useState<number[]>([2000, 2099]);

  const { company } = useSponsorContext();
  const { getState, updateState, refetchPerks } = usePerkState({
    perk: SponsorPerkType.COFFEE_CHATS,
    onSaveSuccess: () => {
      successToast("Starred hacker saved successfully!");
      refetchPerks();
    },
  });

  const favoritedHackers: string[] =
    getState("coffee_chats_favorited_hackers") ?? [];
  const favoritedHackerMap = _.keyBy(favoritedHackers);

  const onFavorite = (hackerId: string) => {
    const isFavorited = favoritedHackers.includes(hackerId);
    updateState(
      "coffee_chats_favorited_hackers",
      isFavorited
        ? favoritedHackers.filter((id) => id !== hackerId)
        : [...favoritedHackers, hackerId]
    );
  };

  useEffect(() => {
    const filterData: THackerFilter[] = [];
    if (starred)
      filterData.push({
        type: "starred",
        value: true,
      });
    if (schools.length > 0)
      filterData.push({
        type: "school",
        value: schools,
      });
    if (programs.length > 0)
      filterData.push({
        type: "program",
        value: programs,
      });
    if (gradYearRange[0] !== 2000 && gradYearRange[1] !== 2099) {
      filterData.push({
        type: "gradYear",
        value: gradYearRange.map(String),
      });
    }
    setSkipped(0);
    setFilters(filterData);
  }, [starred, schools, programs, gradYearRange]);

  const { data, loading: fetchingHackers } = useGetHackersQuery({
    variables: {
      slug: APPLICATIONS_SLUG,
      skip: skipped,
      take: itemsPerPage,
      filters: parseFilters(filters, company?.id, laggedQuery),
    },
  });

  // Stores all hacker profiles
  const [hackerProfiles, setHackerProfiles] = useState<THackerProfile[]>([]);

  useEffect(() => {
    if (data && !fetchingHackers && company) {
      const newProfiles = parseHackerProfiles(data, company.id);
      setHackerProfiles(newProfiles);
    }
  }, [data, fetchingHackers, skipped, company]);

  const handleFetchMoreHackers = (isForward: boolean) => {
    setSkipped((prevSkipped) => {
      if (isForward) {
        return prevSkipped + itemsPerPage;
      } else {
        return Math.max(0, prevSkipped - itemsPerPage);
      }
    });
  };

  const filteredHackerProfiles =
    filters.filter((v) => v.type === "starred").length > 0
      ? hackerProfiles.filter((hacker) =>
          favoritedHackers.includes(hacker.id.toString())
        )
      : hackerProfiles;

  const tableData = filteredHackerProfiles.map((profile: THackerProfile) => {
    const iconName: IconName = favoritedHackerMap[profile.id.toString()]
      ? "selected-star"
      : "not-selected-star";
    return {
      star: (
        <Icon
          style={{ cursor: "pointer" }}
          name={iconName}
          onClick={() => onFavorite(profile.id.toString())}
        />
      ),
      name: profile?.name,
      email: profile?.email,
      school: profile?.school,
      program: profile?.program,
      levelOfStudy: _.startCase(_.toLower(profile?.levelOfStudy)),
      graduationYear: profile?.graduatingYear.toString(),
      resume: (
        <Button
          color="secondary"
          size="sm"
          disabled={!profile?.resume?.uri}
          onClick={() => {
            setSelectedPDFUri(profile?.resume?.uri ?? "");
            setSelectedHackerId(profile?.id.toString());
            setOverlayOpen(true);
          }}
        >
          View
        </Button>
      ),
    };
  });

  const tableColumns = [
    {
      id: "star",
      name: "Star",
      hideLabel: true,
      omit: colsNotVisible.includes("Star"),
    },
    {
      id: "name",
      name: "Name",
      omit: colsNotVisible.includes("Name"),
    },
    {
      id: "email",
      name: "Email",
      omit: colsNotVisible.includes("Email"),
    },
    {
      id: "school",
      name: "School",
      omit: colsNotVisible.includes("School"),
    },
    {
      id: "program",
      name: "Program",
      omit: colsNotVisible.includes("Program"),
    },
    {
      id: "levelOfStudy",
      name: "Level of Study",
      omit: colsNotVisible.includes("Level of Study"),
    },
    {
      id: "graduationYear",
      name: "Graduation Year",
      omit: colsNotVisible.includes("Graduation Year"),
    },
    {
      id: "resume",
      name: "Resume",
      omit: colsNotVisible.includes("Resume"),
    },
  ];

  const schoolMenuItems: DropdownMenuItem[] = [];
  const programMenuItems: DropdownMenuItem[] = [];
  const columnsMenuItems: DropdownMenuItem[] = [];
  const gradYearMenuItems: DropdownMenuItem[] = [];

  const schoolNorthOptions = useMemo(
    () => answersToNorthV2Options(SCHOOL_OPTIONS),
    []
  );
  const programNorthOptions = useMemo(
    () => answersToNorthV2Options(PROGRAM_OPTIONS),
    []
  );
  const gradYearNorthOptions = useMemo(
    () => answersToNorthV2Options(_.range(2000, 2100).map(String)),
    []
  );
  const setupDropdown = () => {
    // Setup School and Program dropdown options
    const dropdownTitle = (title: string) => ({
      content: <EmptyFilterTitleText>{title}</EmptyFilterTitleText>,
      action: () => {},
      disabled: true,
    });
    const dropdownNoFiltersMessage = (message: string) => ({
      content: <EmptyFilterDescText>{message}</EmptyFilterDescText>,
      action: () => {},
      disabled: true,
    });
    const dropdownAddFilter = (onClick: () => void, itemName: string) => ({
      content: (
        <Button
          color="tertiary"
          leadingIcon={<Icon name="spon-plus" size="12px" />}
          size="md"
          style={{
            color: (globalConstants?.color?.textPrimary as string) ?? "",
          }}
          onClick={onClick}
        >
          Add a {itemName}
        </Button>
      ),
      action: () => {},
      disabled: true,
    });

    const dropdownFilters = [
      {
        title: dropdownTitle("School"),
        noFiltersMessage: dropdownNoFiltersMessage(
          "No schools added! Add a school using the button below to view students from that school."
        ),
        addFilter: dropdownAddFilter(
          () => setSchools([...schools, DEFAULT_SCHOOL_TO_ADD_ON_FILTER]),
          "school"
        ),
        items: {
          state: schools,
          setState: setSchools,
          options: schoolNorthOptions,
        },
        menuItems: schoolMenuItems,
      },
      {
        title: dropdownTitle("Program"),
        noFiltersMessage: dropdownNoFiltersMessage(
          "No programs added! Add a program using the button below to view students from that program."
        ),
        addFilter: dropdownAddFilter(
          () => setPrograms([...programs, DEFAULT_PROGRAM_TO_ADD_ON_FILTER]),
          "program"
        ),
        items: {
          state: programs,
          setState: setPrograms,
          options: programNorthOptions,
        },
        menuItems: programMenuItems,
      },
    ];

    dropdownFilters.forEach(
      ({ title, noFiltersMessage, addFilter, items, menuItems }) => {
        if (title) menuItems.push(title);
        if (noFiltersMessage && items.state.length === 0)
          menuItems.push(noFiltersMessage);
        items.state.forEach((item: string, idx: number) => {
          const dropdownItem = (
            <Select
              size="sm"
              onChange={(e) => {
                const newState = [...items.state];
                newState[idx] = e.target.value;
                items.setState(newState);
              }}
              value={item}
            >
              {items.options}
            </Select>
          );
          const deleteButton = (
            <XMarkIcon
              className="w-4 h-4 cursor-pointer text-gray-400"
              onClick={() => {
                const newState = [...items.state];
                newState.splice(idx, 1);
                items.setState(newState);
              }}
            />
          );
          menuItems.push({
            content: (
              <div className="flex items-center justify-between gap-2">
                {dropdownItem}
                {deleteButton}
              </div>
            ),
            action: () => {},
            disabled: true,
          });
        });
        if (addFilter) menuItems.push(addFilter);
      }
    );

    // Setup Column Filter
    columnsMenuItems.push(dropdownTitle("Toggle column visibility"));
    tableColumns.forEach(({ name }) => {
      columnsMenuItems.push({
        content: (
          <Checkbox
            checked={colsNotVisible.includes(name)}
            onChange={() =>
              !colsNotVisible.includes(name)
                ? setColsNotVisible([...colsNotVisible, name])
                : setColsNotVisible(colsNotVisible.filter((v) => v !== name))
            }
            label={name}
          />
        ),
        action: () => {},
        disabled: true,
      });
    });

    // Setup Grad Year Filter
    gradYearMenuItems.push(dropdownTitle("Graduation Year"));
    gradYearMenuItems.push({
      content: (
        <GradYearFilterSelect>
          From<Spacer width={4}></Spacer>
          <Select
            size="sm"
            onChange={(e) => {
              const newState = [...gradYearRange];
              newState[0] = parseInt(e.target.value);
              setGradYearRange(newState);
            }}
            value={gradYearRange[0]}
            error={gradYearRange[0] > gradYearRange[1] && "Range Error"}
          >
            {gradYearNorthOptions}
          </Select>
        </GradYearFilterSelect>
      ),
      action: () => {},
      disabled: true,
    });
    gradYearMenuItems.push({
      content: (
        <GradYearFilterSelect>
          To<Spacer width={4}></Spacer>
          <Select
            size="sm"
            onChange={(e) => {
              const newState = [...gradYearRange];
              newState[1] = parseInt(e.target.value);
              setGradYearRange(newState);
            }}
            value={gradYearRange[1]}
          >
            {gradYearNorthOptions}
          </Select>
        </GradYearFilterSelect>
      ),
      action: () => {},
      disabled: true,
    });
  };

  setupDropdown();
  return (
    <>
      <Header
        title="Recruitment"
        subtitle="View our database of 1,500+ hackers interested in career opportunities"
        actionButton={
          <Button
            leadingIcon={
              <Icon name="spon-download" size="14px" color="rgba(0,0,0,0)" />
            }
            color="primary"
            onClick={() => window.open(HACKER_CSV_DOWNLOAD_LINK, "_blank")}
          >
            Download CSVs
          </Button>
        }
      />
      <Container>
        <div style={{ width: "100%" }}>
          <div
            className="flex justify-between mb-4"
            style={{ alignItems: "center" }}
          >
            <SearchContainer>
              <TextInput
                type="text"
                placeholder="Search for someone..."
                leadingIcon={<Icon name="search" />}
                onChange={(e: any) => setQuery(e.target.value)}
                value={query}
                disabled={fetchingHackers}
                onKeyDown={(e) => {
                  if (e.key === "Enter") setLaggedQuery(query);
                }}
              />
              <Button
                color="primary"
                size="md"
                onClick={() => setLaggedQuery(query)}
              >
                Search
              </Button>
            </SearchContainer>
            <FilterContainer>
              <DropdownFilters>
                <FilterDropdown selectWidth={165} items={[columnsMenuItems]}>
                  <Button
                    trailingIcon={
                      <Icon
                        name="down-arrow"
                        color="rgba(0,0,0,0)"
                        size="12px"
                      />
                    }
                    disabled={fetchingHackers}
                  >
                    <DropdownButtonText type="subtitle">
                      Columns
                    </DropdownButtonText>
                  </Button>
                </FilterDropdown>
                <FilterDropdown selectWidth={145} items={[gradYearMenuItems]}>
                  <Button
                    trailingIcon={
                      <Icon
                        name="down-arrow"
                        color="rgba(0,0,0,0)"
                        size="12px"
                      />
                    }
                    disabled={fetchingHackers}
                  >
                    <DropdownButtonText type="subtitle">
                      Graduation Year
                    </DropdownButtonText>
                  </Button>
                </FilterDropdown>
                <FilterDropdown selectWidth={165} items={[programMenuItems]}>
                  <Button
                    trailingIcon={
                      <Icon
                        name="down-arrow"
                        color="rgba(0,0,0,0)"
                        size="12px"
                      />
                    }
                    disabled={fetchingHackers}
                  >
                    <DropdownButtonText type="subtitle">
                      Filter by program
                    </DropdownButtonText>
                  </Button>
                </FilterDropdown>
                <FilterDropdown selectWidth={165} items={[schoolMenuItems]}>
                  <Button
                    trailingIcon={
                      <Icon
                        name="down-arrow"
                        color="rgba(0,0,0,0)"
                        size="12px"
                      />
                    }
                    disabled={fetchingHackers}
                  >
                    <DropdownButtonText type="subtitle">
                      Filter by school
                    </DropdownButtonText>
                  </Button>
                </FilterDropdown>
              </DropdownFilters>
              <Checkbox
                onChange={() => setStarred(!starred)}
                checked={starred}
                label="Only show starred"
                size={16}
                disabled={fetchingHackers}
              />
            </FilterContainer>
          </div>
          <>
            {fetchingHackers ? (
              <Spinner text="Loading candidates..." />
            ) : (
              <Table
                columns={tableColumns}
                data={tableData}
                displayStyle="divide-xy"
                horizontalPadding={16}
                verticalPadding={10}
              />
            )}
            <div
              className="flex items-center justify-between mt-4"
              style={{ paddingBottom: "16px" }}
            >
              <div className="flex items-center gap-4">
                <span className="text-text-secondary">Items per page:</span>
                <Select
                  size="sm"
                  onChange={(e) => {
                    setItemsPerPage(parseInt(e.target.value));
                    setSkipped(0);
                  }}
                  value={itemsPerPage}
                  disabled={fetchingHackers}
                >
                  <Select.Option value={10}>10</Select.Option>
                  <Select.Option value={20}>20</Select.Option>
                  <Select.Option value={30}>30</Select.Option>
                  <Select.Option value={40}>40</Select.Option>
                  <Select.Option value={50}>50</Select.Option>
                </Select>
              </div>
              <ButtonGroup>
                <Button
                  size="sm"
                  disabled={page === 0}
                  onClick={() => {
                    setPage(Math.max(0, page - 1));
                    handleFetchMoreHackers(false);
                  }}
                >
                  <Icon name="chevron-left" color="#000" className="w-4 h-4" />
                </Button>
                <Button
                  size="sm"
                  disabled={hackerProfiles.length < itemsPerPage}
                  onClick={() => {
                    setPage(page + 1);
                    handleFetchMoreHackers(true);
                  }}
                >
                  <Icon name="chevron-right" color="#000" className="w-4 h-4" />
                </Button>
              </ButtonGroup>
            </div>
          </>
        </div>
      </Container>
      <Overlay open={overlayOpen} onClose={() => setOverlayOpen(false)}>
        <div>
          <Document
            file={selectedPDFUri}
            onLoadSuccess={({ numPages }) => setNumPDFPages(numPages)}
            onLoadError={(e) => {
              errorToast(e.message);
              setOverlayOpen(false);
            }}
            externalLinkTarget="_blank"
            loading="Loading PDF..."
          >
            <Page pageNumber={pagePDFNumber} />
          </Document>
          <PDFPagintation>
            <EmptyFilterTitleText
              style={{ color: globalConstants?.color?.white as string }}
            >
              {pagePDFNumber} of {numPDFPages}
            </EmptyFilterTitleText>
            <OverlayButtonGroup>
              <Button
                color="secondary"
                leadingIcon={
                  <Icon
                    name={
                      favoritedHackerMap[selectedHackerId]
                        ? "selected-star"
                        : "not-selected-star"
                    }
                    size="12px"
                  />
                }
                onClick={() => onFavorite(selectedHackerId)}
                size="sm"
              >
                {favoritedHackerMap[selectedHackerId] ? "Unstar" : "Star"}
              </Button>
              <Button
                color="primary"
                onClick={() => window.open(selectedPDFUri, "_blank")}
                size="sm"
              >
                Download
              </Button>
              <ButtonGroup>
                <Button
                  size="sm"
                  disabled={pagePDFNumber === 1}
                  onClick={() =>
                    setPagePDFNumber(Math.max(0, pagePDFNumber - 1))
                  }
                >
                  <Icon name="chevron-left" color="#000" className="w-4 h-4" />
                </Button>
                <Button
                  size="sm"
                  disabled={pagePDFNumber >= (numPDFPages ?? 1)}
                  onClick={() =>
                    setPagePDFNumber(
                      Math.min(numPDFPages ?? 1, pagePDFNumber + 1)
                    )
                  }
                >
                  <Icon name="chevron-right" color="#000" className="w-4 h-4" />
                </Button>
              </ButtonGroup>
            </OverlayButtonGroup>
          </PDFPagintation>
        </div>
      </Overlay>
    </>
  );
};

const DropdownButtonText = styled(Text)`
  font-size: 13px !important;
  font-weight: 500;
  color: ${globalConstants?.color?.textBody as string};
`;

const DropdownFilters = styled(Flex)`
  column-gap: 4px;
`;

const GradYearFilterSelect = styled(Flex).attrs({
  row: true,
  justify: "space-between",
  align: "center",
})`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${(globalConstants?.color?.textTertiary as string) ?? ""};
  row-gap: 10px;
`;

const OverlayButtonGroup = styled(Flex).attrs({
  align: "center",
})`
  column-gap: 8px;
`;

const PDFPagintation = styled(Flex).attrs({
  align: "center",
  justify: "space-between",
})`
  margin-top: 10px;
`;

const Container = styled(Flex).attrs({})`
  width: 100%;
  height: calc(100vh - ${TOPNAVBAR_HEIGHT}px);
  padding: 42px;
`;

const FilterContainer = styled(Flex).attrs({
  align: "center",
})`
  column-gap: 25px;
`;

const FilterDropdown = styled(DropdownMenu)<{ selectWidth: number }>`
  & * {
    max-width: 100%;
  }

  & select {
    width: ${({ selectWidth }) => selectWidth}px;
  }
`;

const SearchContainer = styled(Flex).attrs({ align: "center" })`
  column-gap: 5px;
`;

const EmptyFilterDescText = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #000;
  text-align: left;
`;

const EmptyFilterTitleText = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: ${(globalConstants?.color?.textBody as string) ?? ""};
`;

export default SponsorRecruitment;
